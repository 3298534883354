import MobileNavigation from "./modules/navigation";
import Slider from './modules/slider';
import $ from 'jquery';

window.$ = $;
window.jQuery = $;

const getSearchTopLink = document.querySelector(".search-top-link");
const pageSearchTop = document.querySelector('.page-top-search');

getSearchTopLink.addEventListener('click', (e) => {
    e.preventDefault();
    if(pageSearchTop.classList.contains('show')) {
        pageSearchTop.classList.remove('show');
    } else {
        pageSearchTop.classList.add('show');
    }
});

const skipLinkToFocus = () => {
    var isIe = /(trident|msie)/i.test(navigator.userAgent);

    if (isIe && document.getElementById && window.addEventListener) {
        window.addEventListener('hashchange', function () {
            var id = location.hash.substring(1),
                element;

            if (!(/^[A-z0-9_-]+$/.test(id))) {
                return;
            }

            element = document.getElementById(id);

            if (element) {
                if (!(/^(?:a|select|input|button|textarea)$/i.test(element.tagName))) {
                    element.tabIndex = -1;
                }

                element.focus();
            }
        }, false);
    }
};

const initJS = () => {
    const mobileNavigation = new MobileNavigation();
    // const customizer = new Customizer();
    const sliders = $(".slider");
    sliders.each(function( index ) {
        const initSlider = new Slider(this);
    });
};

$(document).ready(function () {
    initJS();
    skipLinkToFocus();
});

/*
let productsContainer = document.querySelector(".products");
let navContainer = document.querySelector(".navbar-mobile");
let frontpage = document.querySelector(".fullpage-image");

if(productsContainer) {
    let initSorting = new MealBoxSorting(productsContainer);
}

if(navContainer) {
    let initMobileNav = new MobileNav(navContainer);
}

if(frontpage) {
    let initSearch = new SearchMealbox(frontpage);
}

const instance = Layzr()

// start it up, when the DOM is ready

document.addEventListener('DOMContentLoaded', event => {
  instance
    .update()           // track initial elements
    .check()            // check initial elements
    .handlers(true)     // bind scroll and resize handlers
})

// Get the header
const navBar = document.querySelector(".navbar");
// Get the offset position of the navbar
const sticky = navBar.offsetTop;

// Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
function addClassToHeaderOnScroll() {
    if (window.pageYOffset > sticky) {
        navBar.classList.add("sticky");
    } else {
        navBar.classList.remove("sticky");
    }
}

window.onscroll = function() {addClassToHeaderOnScroll()};
*/
import $ from 'jquery';
import 'slick-carousel/slick/slick';
/**
 * File customizer.js.
 *
 * Theme Customizer enhancements for a better user experience.
 *
 * Contains handlers to make Theme Customizer preview reload changes asynchronously.
 */

class Slider {

    /**
     * The constructor is fired automatically once you run "new Example()".
     *
     * @param {HTMLElement} domReference
     */
    constructor(container) {
        // Set up the DOM and stuff like that as soon as the browser is ready for it.
        this.init(container);
    }

    /**
     *
     * @param {HTMLElement} domReference
     */
    init(container) {
        this.dom = {
            container // ES6 allows this syntax instead of writing "container: container"
        };

        const sliderElement = this.dom.container;

        $(sliderElement).slick({
            infinite: false,
            speed: 300,
            adaptiveHeight:false,
            variableWidth: false,
            slidesToScroll: 1,
            dots: false,
            arrows: true,
        });
    }

}

export default Slider;


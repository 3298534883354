class MobileNavigation {

    /**
     * The constructor is fired once the class is instantiated.
     *
     * @param {HTMLElement} container
     */
    constructor() {
        // Run initializing code once the DOM is ready.
        this.init();
    }


    /**
     * The actual initialization function, fired once the DOM is ready.
     *
     * @param {HTMLElement} container
     */
    init() {
        console.log('in nav');
        this.dom = {
            navTrigger: document.querySelector(".hamburger"),
            navBurger: document.querySelector(".burger"),
            appHeader: document.querySelector(".header"),
            appNav: document.querySelector(".main-menu"),
            isNavOpen: false
        };

        if (this.dom.navTrigger) {
            this.dom.navTrigger.addEventListener("click", () => {
                this.dom.isNavOpen = !this.dom.isNavOpen;
                this.addClassesToElements();
            });
        }

    }

    addClassesToElements() {
        if (!this.dom.isNavOpen) {
            this.dom.appHeader.classList.remove("menu-open");
            this.dom.navTrigger.classList.remove("hamburger--spin", "is-active");
        } else {
            this.dom.appHeader.classList.add("menu-open");
            this.dom.navTrigger.classList.add("hamburger--spin", "is-active");
        }
    }

}


export default MobileNavigation;